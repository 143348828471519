<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        A metal ore is digested with concentrated HNO3 together with
        <chemical-latex content="KClO3\text{.}" />
        This treatment dissolves the ore and simultaneously converts the elemental sulfur
        <chemical-latex content="(S)" />
        present to sulfate. The nitrate and chlorate are removed by repeatedly adding concentrated
        <chemical-latex content="HCl" />
        and heating to dryness. The sulfate is then precipitated as
        <chemical-latex content="BaSO4" />
        which is separated, dried, and weighed. If the ore sample weighed
        <number-value :value="ore" unit="\text{g,}" />
        and the dried
        <chemical-latex content="BaSO4" />
        weighed
        <number-value :value="BaSO4" unit="\text{g,}" />
        calculate the % by mass of sulfur in the original sample of ore.
      </p>

      <calculation-input
        v-model="inputs.studentAnswer"
        append-text="$\ce{\%}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import StembleLatex from '@/tasks/components/StembleLatex';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';

export default {
  name: 'ChemUPEI2210A3_Q3',
  components: {
    ChemicalLatex,
    CalculationInput,
    NumberValue,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        studentAnswer: null,
      },
    };
  },
  computed: {
    ore() {
      return this.taskState.getValueBySymbol('ore').content;
    },
    BaSO4() {
      return this.taskState.getValueBySymbol('BaSO4').content;
    },
  },
};
</script>
